import React from 'react';
import Pages from '../components/Pages';
import OurApproach from '../components/pages/ourApproach';

const OurApproachPage = () => {
  return (
    <Pages>
      <OurApproach />
    </Pages>
  );
};

export default OurApproachPage;
