import React from 'react';
import imageRing from 'images/ourApproach/image-ring.png';
import imageChain from 'images/ourApproach/image-chain.png';
import imageDiamond from 'images/ourApproach/image-diamond.png';
import imageAbstract from 'images/ourApproach/image-abstract.png';
import SaltTimeline from 'images/ourApproach/salt-timeline.png';
import SectionDragCarousel from 'components/pages/homepage/SectionDragCarousel';
import SlideUpFade from 'components/animation/SlideUpFade';
import { useWindowWidth, useMediaQuery } from 'components/hooks/useResponsive';
import PropTypes from 'prop-types';
import CustomContainer from '../../CustomContainer';
import reactHtmlParser from 'react-html-parser';

const CardOurInvestment = ({ title, description, image }) => {
  return (
    <div className="text-brand-black font-light flex justify-center flex-col items-center lg:items-start">
      <img src={image} alt="ring" className="lg:w-56 w-36 " />
      <p className="mt-4 text-center lg:text-start text-[20px] sm:text-[22px] font-semibold">
        {reactHtmlParser(title)}
      </p>
      <p className="text-[16px] sm:text-[18px] mt-3 text-center lg:text-start sm:w-[70%] lg:w-[100%]">
        {description}
      </p>
    </div>
  );
};

const ImageWrapper = () => {
  return (
    <div
      className="w-full lg:w-[75%] h-[250px] sm:h-[500px] relative overflow-hidden
    shadow-[3px_10px_15px_-3px_rgb(0,0,0,0.5)]"
    >
      <div className={`banner-our-approach pointer-events-none`} />
    </div>
  );
};

const OurApproachPage = () => {
  const windowWidth = useWindowWidth();
  const mediaQuery = useMediaQuery();

  return (
    <div className="pt-20 bg-brand-black text-brand-white min-h-screen">
      <CustomContainer className="py-12">
        <div className="flex flex-col items-center justify-center">
          <SlideUpFade>
            <div className="sm:px-14 flex justify-center">
              <p className="text-base sm:text-md lg:text-lg font-thin text-center mb-4 lg:w-[90%]">
                SALT Ventures is one of the most impactful and influential big
                group holding companies in Indonesia.
              </p>
            </div>
          </SlideUpFade>
          <ImageWrapper />
        </div>
        <SlideUpFade>
          <div className="mt-10 flex justify-end font-thin w-full sm:px-10">
            <div className="flex w-full lg:w-1/2 items-start">
              <div className="border w-40 mt-3 mr-10" />
              <p className="text-sm sm:text-[18px]">
                We focus to invest in and help to cultivate the impactful early
                to growth stage companies in Indonesia’s growing market.
              </p>
            </div>
          </div>
        </SlideUpFade>
        <SlideUpFade>
          <div className="flex flex-col lg:flex-row items-start mt-10 sm:px-10">
            <span className="text-base sm:text-md font-medium w-full lg:w-1/2 mb-4 lg:mb-0">
              SALT Stories
            </span>
            <div className="font-thin space-y-4">
              <p className="text-sm sm:text-[20px]">
                We believe that for a company to thrive and win in this
                competitive market, it needs to be either technologically driven
                and/or efficiently run and operated by a value-driven and highly
                motivated management team.
              </p>
              <p className="text-sm sm:text-[20px]">
                When we invest, our frame of mind is not as an investor. We
                consider ourselves partners of the founders, big dreamers, and
                extra-mile walkers.
              </p>
            </div>
          </div>
        </SlideUpFade>
      </CustomContainer>
      <div
        style={{
          background:
            windowWidth >= mediaQuery.md
              ? 'linear-gradient(to bottom, #192028 10%, #fff 50%)'
              : '#fff',
        }}
        className="min-h-screen "
      >
        <div className="lg:pt-16 pt-0 pb-16 sm:pb-0">
          <div className="2xl:container 2xl:mx-auto flex justify-center items-center">
            <div className="shadow-[3px_10px_15px_-3px_rgb(0,0,0,0.5)] w-full lg:w-[63%] sm:h-[450px] xl:h-[fit-content]">
              <video
                className="w-full h-full object-cover"
                autoPlay
                playsInline
                muted
                loop
              >
                <source src="https://finfolk.ap-south-1.linodeobjects.com/salt-banner-video-landscape.mp4" />
              </video>
            </div>
          </div>
          <CustomContainer className="pb-0 md:pb-28">
            <div className="text-brand-black flex flex-col items-center mt-20 lg:mt-28">
              <p className="text-base sm:text-[32px] font-semibold text-center">
                Our Investment Philosophy
              </p>
              <p className="text-sm sm:text-[20px] text-center">
                We go the extra mile to generate optimal return through four
                tried and true philosophies.
              </p>
            </div>
            <div className="pl-0 lg:pl-24 w-full ">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-32 w-full lg:w-[75%] mt-16">
                <SlideUpFade>
                  <CardOurInvestment
                    title="Quality Founders<br/>= Quality Results"
                    description="Behind every great business is a great entrepreneur."
                    image={imageRing}
                  />
                </SlideUpFade>
                <SlideUpFade>
                  <CardOurInvestment
                    title="Long-Term Scalable Vision"
                    description="Our long-term perspective moves us from the realm of the unpredictable to the realm of certainty."
                    image={imageChain}
                  />
                </SlideUpFade>
              </div>
            </div>
            <div className="w-full pl-0 lg:pl-24 ">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-32 w-full lg:w-[75%] mt-12 ml-0 lg:ml-44">
                <SlideUpFade>
                  <CardOurInvestment
                    title="At the Forefront of<br/>Implementable Innovation"
                    description="We believe that innovation drives growth. We keep our eyes focused on companies that have innovative business models to solve customers’ problems."
                    image={imageDiamond}
                  />
                </SlideUpFade>
                <SlideUpFade>
                  <CardOurInvestment
                    title="We Focus on Creating Ecosystems"
                    description="We focus on creating the right ecosystem where each and every one of our investee companies can grow and prosper."
                    image={imageAbstract}
                  />
                </SlideUpFade>
              </div>
            </div>
          </CustomContainer>
        </div>
      </div>
      <SectionDragCarousel />
      <div className="bg-brand-white py-24">
        <CustomContainer>
          <div className="text-brand-black flex flex-col items-center">
            <p className="text-base sm:text-[32px] font-semibold text-center">
              Our Investment Approach
            </p>
            {/* <p className="text-sm sm:text-[20px] text-center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p> */}
          </div>
          <SlideUpFade>
            <div className="w-full flex justify-center align-center mt-6">
              <img
                src={SaltTimeline}
                alt="salt-timeline"
                style={{
                  width: windowWidth >= mediaQuery.lg ? '90%' : '100%',
                  height: 'auto',
                  pointerEvents: 'none',
                }}
              />
            </div>
          </SlideUpFade>
        </CustomContainer>
      </div>
    </div>
  );
};

CardOurInvestment.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default OurApproachPage;
